<template>
    <div id="services" class="xl:container mx-auto font-sans lg:px-8 px-2 sm:py-16 py-5">
        <h1 class="md:text-[56px] text-[32px] sm:pb-[60px] pb-10 dark:text-gray-100">Сервис для междугородных поездок</h1>
        <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 text-xl">
            <div class="bg-white rounded-[48px] p-8">
                <img src="@/assets/icon1.svg" alt="">
                <h2 class="md:text-2xl text-[20px] font-bold md:py-5 py-3">
                    Предлагайте свою цену
                </h2>
                <p class="md:text-xl text-[16px]">
                    Укажите цену за поездку и ждите звонок водителя
                </p>
            </div>
            <div class="bg-white rounded-[48px] p-8">
                <img src="@/assets/icon2.svg" alt="">
                <h2 class="md:text-2xl text-[20px] font-bold md:py-5 py-3">
                    Выберите водителя
                </h2>
                <p class="md:text-xl text-[16px]">
                    Вы заранее узнаете время поездки, марку и модель авто для удобства
                </p>
            </div>
            <div class="bg-white rounded-[48px] p-8">
                <img src="@/assets/icon3.svg" alt="">
                <h2 class="md:text-2xl text-[20px] font-bold md:py-5 py-3">
                    Предварительное бронирования
                </h2>
                <p class="md:text-xl text-[16px]">
                    Будьте уверены, что у вас уже есть забронированное место
                </p>
            </div>
        </div>
    </div>
</template>