<template>
    <div>
      <card-detail
        :status="detail.status"
        :startDate="detail.start_date"
        :price="detail.price"
        :seatCount="detail.seat_count"
        comment="Нет комментариев"
        :from_city="detail.from_city"
        :to_city="detail.to_city"
        :marka="detail.marka"
        :model="detail.model"
      />
      <a :href="this.$route.path.includes('/performer/') ? 'https://hamroh.com/app/performer/trips' : 'https://hamroh.com/app/passenger/trips'">
        <div class="mx-auto w-[410px] border text-center font-bold mb-12 mt-4 rounded-lg bg-gray-400 py-3 cursor-pointer">
              Показать номер телефона
        </div>
      </a>


    </div>
  </template>
<script>

import CardDetail from '@/components/cards/CardDetail.vue'
import api from '@/api/axios';

export default {
    components: {
        CardDetail,
    },
    data(){
      return {
        detail: {}
      }
    },
    mounted(){
      this.getTripDetail()
      console.log(this.$route.params.id);
    },
    methods: {
      getTripDetail(){
        api.get(`v1/get/performer-trip?id=${this.$route.params.id}`)
          .then(res=> {
            this.detail = res.data.result.data[0]
          })
      }
    }
}
</script>