<template>
    <div class="pb-20 bg-gray-100 dark:bg-slate-900">
        <!-- <div class="border w-[354px] rounded-md text-center border-gray-500 bg-gray-50 text-lg py-2 cursor-pointer fixed left-1/2 -translate-x-1/2 top-24">
            <h1>Поездки пассажиров</h1>
        </div> -->
        <div class="mt-[22px]">
            <template v-if="loader">
                <CardSkeleton v-for="n in 5" :key="n" />
            </template>
            <template v-else>
                <div v-for="(trip, id) in trips" :key="id">
                    <!-- <router-link :to="{ name: 'PassengerTripDetail', params: { id: trip.id } }"> -->
                    <a href="https://hamroh.com/app/performer/trips">
                        <CardTrip :from="trip.from_city" :to="trip.to_city" :price="trip.price" :date="trip.start_date"
                            class="cursor-pointer" />
                    </a>
                </div>
                <a href="https://hamroh.com/app/performer/trips">
                    <slot></slot>
                </a>
            </template>
        </div>
    </div>
</template>
<script>

import CardTrip from '@/components/cards/CardTrip.vue';
import CardSkeleton from '@/components/cards/CardSkeleton.vue'
import api from '@/api/axios';

export default {
    components: {
        CardTrip,
        CardSkeleton
    },
    data() {
        return {
            trips: [],
            loader: true,
            maxTrips: 5,
            params: {
                from_city_id: this.$route.query.selectCityId,
                to_city_id: this.$route.query.selectedCityToId,
                // seat_count: this.$route.query.selectedSeats,
                start_date: this.$route.query.startdate
            },
        }
    },
    mounted() {
        this.getPassTrip()
    },
    methods: {
        getPassTrip() {
            api.get('v1/get/passenger-trip', { params: this.params })
                .then(res => {
                    this.trips = res.data.result.data
                    this.loader = false
                    this.$emit('updateTripCount', this.trips.length);
                })
        }
    },
    computed: {
        // limitedTrips() {
        //     return this.trips.slice(0, this.maxTrips);
        // },
        tripCount() {
            return this.trips.length;
        }
    },
}
</script>

<style></style>