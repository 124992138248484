import { createApp, defineAsyncComponent} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead } from '@vueuse/head'
import '../src/main.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
const PostCard = defineAsyncComponent(() => import('@/components/CardPost/postCard.vue'))
const FilterCard = defineAsyncComponent(() => import('@/components/jobs/FilterCard.vue'))
const Pagination = defineAsyncComponent(() => import('@/components/pagination/pagination.vue'))
const Search = defineAsyncComponent(() => import('@/components/search/search.vue'))

let app = createApp(App)
const head = createHead()
app.use(store)
app.use(router)
app.use(head)
app.use(VueAxios, axios)
app.component('filter-card', FilterCard, )
app.component('post-card', PostCard, )
app.component('pagination', Pagination, )
app.component('search', Search, )
app.mount('#app')