<template>
    <div class="bg-gray-100 flex items-center justify-center h-screen dark:bg-slate-900">
        <div class="text-center">
            <h1 class="text-7xl font-bold text-gray-800 mb-4 dark:text-gray-100">Скоро</h1>
            <p class="text-gray-600 mb-2 text-2xl dark:text-gray-400">Это страница в стадии разработки!</p>
            <router-link to="/">
                <p class="text-2xl dark:text-gray-200">
                    Главная страница
                </p>
            </router-link>
        </div>
    </div>
</template>