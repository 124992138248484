<template>
  <div class="xl:container mx-auto font-sans lg:px-8 px-2">
    <h1 class="text-[56px]">Блог</h1>
    <div class="grid xl:grid-cols-3 gap-4 sm:grid-cols-2">
      <post-card
        v-for="blog, in blogs"
        :key="blog"
        :image="blog.img"
        :title="blog.title"
        :date="blog.date"
        :url="`/blogDetail/${blog.id}`" 
        cardWidth="full"
      />
    </div>
  </div>
</template>

<script>
import blogs from '@/data/blogs';
export default {
  data() {
    return {
      blogs: blogs,
    };
  },
};
</script>
