<template>
  <div class="xl:container mx-auto font-sans lg:px-8 px-2 py-4">
    <h2 class="text-[2.5rem] font-semibold mb-5">Актуальные вакансии</h2>
    <p class="text-[1.125rem] lg:max-w-[470px]">Выберите вакансию, отправьте резюме и пройдите тесты, чтобы стать частью нашей семьи.</p>
    <div class="relative w-full my-10">
    <input v-model="searchQuery" @key.enter="searchJobs" type="text" id="searchInput" class="p-4 w-full rounded-lg focus:outline-0 drop-shadow-xl" placeholder="Введите название вакансии" required>
    <button @click="searchJobs" type="button" class="absolute top-0 right-0 px-5 h-full text-white bg-[#07CAFF] rounded-r-lg">
      <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
      </svg>
    </button>
    </div>
    <div class="lg:flex">
      <div class="text-[16px]">
        <filter-card title="Регион" @reset-filters-category="resetFilters" :active-filter="activeFilter">
          <button @click="toggleList('showList')" class="w-full mt-6 lg:mt-0 bg-white rounded py-3.5 px-5 flex items-center justify-between">
          {{ selectedCountry }}
          <img src="@/assets/arrow.svg" alt="" :class="{'transform rotate-180 duration-200': showList }" class="duration-200">
          </button>
            <ul v-if="showList" class="mt-1 transition-all duration-200">
              <li v-for="country in countries" :key="country" @click="selectCountry(country)" class="mx-0"><a class="w-full py-3.5 px-5 rounded hover:bg-slate-300 transition inline-block cursor-pointer">{{ country }}</a></li>
            </ul>
            <button @click="toggleList('showListCity')" class="w-full bg-white rounded py-3.5 px-5 flex items-center justify-between mt-6">
              {{ selectedCity }}
              <img src="@/assets/arrow.svg" alt="" :class="{'transform rotate-180 duration-200': showListCity }" class="duration-200">
            </button>
            <ul  v-if="showListCity" class="mt-1 transition-all duration-200">
              <li v-for="city in cities" :key="city" @click="selectCity(city)" class="mx-0"><a class="w-full py-3.5 px-5 rounded hover:bg-slate-300 transition inline-block cursor-pointer">{{ city }}</a></li>
            </ul>
        </filter-card>
        <filter-card title="Категория" @reset-filters-category="resetFiltersCategory" :active-filter="activeCategoryFilter">
          <div class="mt-4 lg:mt-0">
            <button v-for="category in categories" :key="category" class="py-4 flex hover:bg-white" :class="['relative w-full mb-4 rounded py-3.5 px-5', {'bg-white': category === activeCategory}]" :buttonText="category" @click="toggleButton(category)">   
              {{ category }}
              <span class="ml-2 text-gray-500">{{ categoryCounts[category] }}</span>
            </button>
          </div>
      </filter-card>
    </div>
    <div class="w-full pt-6 px-6">
      <div v-for="job in displayedJobs" :key="job" class="bg-white rounded-md p-6 hover:drop-shadow-xl hover:text-sky-400 duration-300 mb-6 cursor-pointer">
        <a :href="`/jobDetail/${job.id}`">
        <div class="lg:flex justify-between">
          <h2 class="text-[1.75rem] font-bold">{{ job.title }}</h2>
          <div class="flex">
              <img src="@/assets/location.svg" alt="">
              <h4 class="text-[#949494] mx-1 lg:my-2 my-4">{{ job.location}}</h4>
          </div>
          </div>
        <p class="text-[18px] lg:pt-6 text-black">{{ job.shortDiscript }}</p>
        </a>
      </div>
      <div v-if="hasDisplayedJobs">
    <pagination :totalPages="totalPages" :currentPage="currentPage" @change="changePage"></pagination>
  </div>
  <div v-else class="pt-6 px-6 text-[#222]">
    <h2 class="text-[28px] font-bold">Пусто</h2>
    <p class="text-[16px] py-4">К сожалению, у нас пока нет подходящих вакансий по вашему запросу.</p>
    <h4 @click="resetAllFilters" class="text-[#07CAFF] text-[18px] hover:underline underline-offset-8 cursor-pointer">Посмотреть другие вакансии</h4>
  </div> 
    </div>
  </div>
  <div class="mt-10">
    <h2 class="text-[1.75rem] font-bold my-8">Часто задаваемые вопросы</h2>
    <card-jobs
    v-for="(question, index) in questions" :key="index"
    :title="question.title"
    :description="question.text"
    :showDesc="activeQuestionIndex === index" 
    @click="toggleQuestion(index)"
    >
      
    </card-jobs>
  </div>
  </div>
</template>

<script>
  import CardJobs from '@/components/cards/CardJobs.vue'
  import jobs from "@/data/jobs.json";

export default {
  components: {CardJobs},
  data() {  
    return {
      countries: ['Таджикистан'],
      cities: ['Душанбе', 'Худжанд', 'Бохтар', 'Турсунзаде', 'Вахдат', 'Гиссар', 'Куляб', 'Истаравшан', 'Исфара', 'Пенджикент'],
      categories: ['Все категории', 'Программисты', 'Работа в Hamroh Академии', 'Hamroh Академия', 'ИТ специалисты', 'Начало карьеры', 'Административный персонал', 'Бухгалтерия, финансы и аудит', 'Маркетинг и реклама', 'Юристы'],

      questions: [
        {title: 'Когда будут известны результаты тестирования?', text: 'Время рассмотрения зависит от количества полученных заявок, обычно от трех дней до одной недели. О результатах кандидаты будут уведомлены по электронной почте или по телефонному звонку.'},
        {title: 'Сколько всего этапов отбора?', text: 'Количество этапов отбора зависит от выбранной вакансии, обычно это два этапа тестирования и два собеседования.'},
        {title: 'Обязательно ли сдавать онлайн тестирование?', text: 'Да, сдавать онлайн тестирование обязательно. Без прохождения онлайн тестирования вы не сможете перейти на следующие этапы.'},
        {title: 'Можно ли пройти этапы отбора дистанционно?', text: 'Да, оставьте заявку и мы с вами свяжемся.'},
        {title: 'Могу ли я повторно оставить заявку на одну и ту же вакансию?', text: 'Заявку можно оставить повторно на одну и ту же вакансию через два месяца.'},
        {title: 'Могу ли я оставить заявку на несколько вакансий одновременно?', text: 'Да, но можем принять вас на работу только на одну позицию.'},
        {title: 'Какая у вас заработная плата?', text: 'Заработная плата каждого кандидата рассматривается индивидуально.'},
        {title: 'Почему меня не приняли на работу?', text: 'На этапе тестирования необходимо набрать минимальный проходной бал. Кандидаты, не набравшие его, отвергаются. В этапе собеседования кандидаты выбираются сравнительно по различным индивидуальным факторам.'},
      ],
      jobs: jobs,
      searchQuery: "",
      showList: false,
      showListCity: false,
      activeQuestionIndex: null,
      selectedCountry: 'Все страны',
      selectedCity: 'Все города',
      activeCategory: 'Все категории',
      currentPage: 1,
      jobsPerPage: 10,
    };
  },
  methods: {
    toggleList(propName) {
      this[propName] = !this[propName];
    },
    toggleButton(category) {
      this.activeCategory = category;
      this.currentPage = 1;
    },
    toggleQuestion(index) {
    if (this.activeQuestionIndex === index) {
      this.activeQuestionIndex = null;
    } else {
      this.activeQuestionIndex = index; 
    }
  },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.showList = false;
      this.currentPage = 1;

    },
    selectCity(city) {
      this.selectedCity = city;
      this.showListCity = false
      this.currentPage = 1;
    },
    resetFilters() {
      this.selectedCountry = 'Все страны';
      this.selectedCity = 'Все города';
      this.currentPage = 1;
    },
    resetFiltersCategory() {
      this.activeCategory = 'Все категории';
      this.currentPage = 1;
    },
    resetAllFilters() {
    this.selectedCountry = 'Все страны';
    this.selectedCity = 'Все города';
    this.activeCategory = 'Все категории';
    this.searchQuery = "";
    this.currentPage = 1;
  },
    searchJobs() {
      this.currentPage = 1;
  },
  },
  computed: {
    activeFilter() {
    return this.selectedCountry !== 'Все страны' || this.selectedCity !== 'Все города';
    },
    activeCategoryFilter() {
    return this.activeCategory !== 'Все категории';
    },
    hasDisplayedJobs() {
      return this.displayedJobs.length > 0;
    },
    displayedJobs() {
      const startIndex = (this.currentPage - 1) * this.jobsPerPage;
      const endIndex = startIndex + this.jobsPerPage;
      const filteredJobs = this.jobs.filter((job) => {
      const title = job.title.toLowerCase();
      const city = job.location.toLowerCase();
      const filterId = job.filterId.toLowerCase();
      
      return (
        (title.includes(this.searchQuery.toLowerCase()) || city.includes(this.searchQuery.toLowerCase())) &&
        (this.selectedCity === 'Все города' || city.includes(this.selectedCity.toLowerCase())) &&
        (this.activeCategory === 'Все категории' || filterId.includes(this.activeCategory.toLowerCase()))
      );
    });
      return filteredJobs.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.jobs.length / this.jobsPerPage);
    },
    filteredJobsByCity() {
    return this.jobs.filter((job) => {
      const city = job.location.toLowerCase();
      return this.selectedCity === 'Все города' || city.includes(this.selectedCity.toLowerCase());
    });
  },
  categoryCounts() {
    const counts = {};
    this.categories.forEach(category => {
      if (category === 'Все категории') { 
        counts[category] = this.filteredJobsByCity.length;  
      } else {
        counts[category] = this.filteredJobsByCity.filter(job => job.filterId === category).length;
      }
    });
    return counts;
  }
  },
};
</script>
