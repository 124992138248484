<template>
    <div class="bg-gray-100 dark:bg-slate-900" >
      <div>
        <Header  v-if="showBanner" />
      </div>
        <!-- <Service /> -->
        <Trips class="md:hidden"/>
        <LeadMagnit />
        <Statistic />
        <Safety />
        <Instruction />
        <FAQ />
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
// import Service from '@/components/home/Service.vue';
import Trips from '@/components/home/Trips'
import LeadMagnit from '@/components/home/LeadMagnit.vue';
import Statistic from '@/components/home/Statistic.vue';
import Safety from '@/components/home/Safety.vue';
import Instruction from '@/components/home/Instruction.vue';
import FAQ from '@/components/home/FAQ.vue';

export default {
    components: {
        Header,
        // Service,
        Trips,
        LeadMagnit,
        Statistic,
        Safety,
        Instruction,
        FAQ,
    },
    data() {
      return {
        showBanner: true,
    }
},
methods: {
    isMobileDevice() {
      const mobileWidth = 768;
      return window.innerWidth < mobileWidth;
    },
    checkDeviceType() {
      this.showBanner = !this.isMobileDevice();
    },
  },
  created() {
    this.checkDeviceType();
    window.addEventListener('resize', this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceType);
  },

}
</script>

<style>

</style>