<template>
    <div class="xl:container mx-auto font-sans lg:px-80 px-2 mb-[100px] grid xl:grid-cols-1 gap-8">
        <div>
            <h1 class="text-[32px] font-bold my-6">{{ blog.title }}</h1>
            <p class="text-[#999] my-6">{{ blog.date }}</p>
            <img class="rounded-[48px]" :src="getImageUrl(blog.img)" alt="">
            <div class="mt-6" v-html="blog.text">
            </div>
        </div>
        </div>
   
</template>
  <script>
  import blogs from '@/data/blogs';
  
  export default {
    data() {
      return {
        
      };
    },
    created() {
      this.blogId = this.$route.params.id;
      this.blog = this.getBlogById(this.blogId);
    },
    methods: {
      getBlogById(id) {
        return blogs.find(blog => blog.id === id) || {};
      },
      getImageUrl(imgPath) {
      return require('@/assets/' + imgPath);
    },
    },
    computed: {
    blog() {
      const blogId = Number(this.$route.params.id);
      return blogs.find(blog => blog.id === blogId) || {};
    },
  },
  };
  </script>
  <style>
p{
    padding: 8px;
}
li{
    margin-left: 40px;
}
</style>