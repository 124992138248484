<template>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 md:py-16 py-10 dark:text-gray-100">
        <h2 class="sm:text-[56px] text-[32px] md:pb-8 pb-12">Наши достижения</h2>
        <div class="grid md:grid-cols-4 grid-cols-2 gap-6">
            <div class="border-t-4 border-[#07CAFF] pt-4">
                <div class="sm:text-7xl text-[32px] font-bold text-center">
                    <!-- {{ statistic.count_city }} -->
                    <p>79</p>
                </div>
                <div class="text-xl text-center">
                    городов
                </div>
            </div>
            <div class="border-t-4 border-[#07CAFF] pt-4">
                <div class="sm:text-7xl text-[32px] font-bold text-center">
                    <!-- {{ statistic.count_trip }} -->
                    <p>2768</p>
                </div>
                <div class="text-xl text-center">
                    поездок
                </div>
            </div>
            <div class="border-t-4 border-[#07CAFF] pt-4">
                <div class="sm:text-7xl text-[32px] font-bold text-center">
                    <!-- {{ statistic.count_passenger }} -->
                    <p>1461</p>
                </div>
                <div class="text-xl text-center">
                    пассажиров
                </div>
            </div>
            <div class="border-t-4 border-[#07CAFF] pt-4">
                <div class="sm:text-7xl text-[32px] font-bold text-center">
                    <!-- {{ statistic.count_performer }} -->
                    <p>289</p>
                </div>
                <div class="text-xl text-center">
                    водителей
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/axios'
export default {
    data() {
        return{
            statistic: [],
        }
    },
    mounted(){
        this.getStatistic()
    },
    methods: {
        getStatistic(){
            api.get('v1/get/web-dashboard/count')
                .then(res=>{
                    this.statistic = res.data.result
                })
        }
    }
}
</script>

<style>

</style>