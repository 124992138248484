<template>
    <div class="pb-20">
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
        <CardTrip :from="item.from_city" :to="item.to_city" :price="item.price" :date="item.start_date" />
    </div>
</template>
<script>

import CardTrip from '@/components/cards/CardTrip.vue';

export default {
    components: {
        CardTrip
    },
    data(){
        return {
            item: {
                from_city: 'Душанбе',
                to_city: 'Худжанд',
                price: 200,
                start_date: 'Дата поездки'
            }
        }
    }
}
</script>

<style>

</style>